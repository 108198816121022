<template>
    <SearchLayout :get-data="searchByKeywords" />
</template>

<script>
    import { mapActions } from 'vuex'
    import SearchLayout from '@/components/Search/SearchLayout.vue'

    export default {
        name: 'SearchByKeywords',
        title: 'searchform.searchbykeyword',
        components: {
            SearchLayout
        },
        methods: {
            ...mapActions('search', ['searchByKeywords'])
        }
    }
</script>
